import { format } from 'date-fns';
import {
	SEND_PAYDOCUID,
	SEND_PAYDOCUCOPY,
	SEND_PAYDOCUFIX,
	FETCH_COMPANIES,
	FETCH_AGENT_COMPANIES,
	FETCH_COMPANY_PROGRAM_USE_CP,
	CLEAR_COMPANY,
	DELETE_COMPANY,
	FETCH_PAYMENTSINFO,
	CLEAR_PAYMENTSINFO,
	FETCH_PAYDOCUS,
	CLEAR_PAYDOCU,
	DELETE_PAYDOCU,
	SET_FILTER_PAYDOCU,
	SET_FILTER_CLIENT_INFO,
	LISTEN_TO_SELECTED_PAYDOCU,
	CLEAR_SELECTED_PAYDOCU,
	SET_YEARMONTH_PAYDOCU,
	SET_WORKDATES_PAYDOCU,
	CLEAR_WORKDATES_PAYDOCU,
	SEND_PAYDOCU_COMPANYNAME,
	FINAL_RETIRE_HEALTHCARE_RECAL_FEE,
	FINAL_RETIRE_OLDCARE_RECAL_FEE,
	SET_PAYDOCU_WORKER_NAME,
	CLEAR_FINAL_RETIRE_HEALTHCARE_RECAL_FEE,
	CLEAR_FINAL_RETIRE_OLDCARE_RECAL_FEE,
	FETCH_WORKERS_OUT,
	DELETE_WORKER_OUT,
	CLEAR_WORKER_OUT,
	FETCH_WORKERS_IN,
	DELETE_WORKER_IN,
	CLEAR_WORKER_IN,
	FETCH_COMPETENCIES,
	DELETE_COMPETENCY,
	CLEAR_COMPETENCY,
	SUPER_ADMIN_FOR_FREE,
	FETCH_WORKER_USER_COMPANY_DATA,
	CLEAR_WORKER_USER_COMPANY_DATA,
	FETCH_PAYDOCUS_ANNUALLEAVES,
	CLEAR_PAYDOCU_ANNUALLEAVES,
	FETCH_LAST_MONTH_PAYDOCUS,
	CLEAR_LAST_MONTH_PAYDOCU,
	DELETE_LAST_MONTH_PAYDOCU,
} from './companyConstants';

const thisYear = format(new Date(), 'yyyy');
const thisMonth = format(new Date(), 'MM');
const yearMonth = thisYear + thisMonth;

const initialState4In = {
	companies: [],
	paydocus: [],
	lastMonthPaydocus: [],
	filter: 'isHosting',
	clientInfo: [],
	selectedPaydocu: null,
	companyProgramUseCPs: [],
	date: new Date(),
	paymentsInfo: [],
	paydocuId: '',
	paydocuCopy: false,
	paydocuFix: false,
	yearMonth: yearMonth,
	paydocuWorkerName: '',
	workDates: [],
	companyName: '',
	finalRetireHealthCareRecalFee: '',
	finalRetireOldCareRecalFee: '',
	workersOut: [],
	workersIn: [],
	competencies: [],
	superAdminForFreeTrue: false,
	workerUserCompanyData: [],
	paydocusAnnualLeaves: [],
};

export default function companyReducer(
	state = initialState4In,
	{ type, payload }
) {
	switch (type) {
		case SUPER_ADMIN_FOR_FREE:
			return {
				...state,
				superAdminForFreeTrue: payload,
			};
		case FETCH_COMPANIES:
			return {
				...state,
				companies: [...state.companies, ...payload.companies],
			};
		case FETCH_AGENT_COMPANIES:
			return {
				...state,
				companies: [...state.companies, ...payload.companies],
			};
		case CLEAR_COMPANY:
			return {
				...state,
				companies: [],
			};
		case DELETE_COMPANY:
			return {
				...state,
				companies: [...state.companies.filter((evt) => evt.id !== payload)],
			};
		case FETCH_WORKERS_OUT:
			return {
				...state,
				workersOut: [...state.workersOut, ...payload.workersOut],
			};
		case CLEAR_WORKER_OUT:
			return {
				...state,
				workersOut: [],
			};
		case DELETE_WORKER_OUT:
			return {
				...state,
				workersOut: [...state.workersOut.filter((evt) => evt.id !== payload)],
			};

		case FETCH_WORKERS_IN:
			return {
				...state,
				workersIn: [...state.workersIn, ...payload.workersIn],
			};
		case CLEAR_WORKER_IN:
			return {
				...state,
				workersIn: [],
			};
		case DELETE_WORKER_IN:
			return {
				...state,
				workersIn: [...state.workers.filter((evt) => evt.id !== payload)],
			};

		case FETCH_COMPETENCIES:
			return {
				...state,
				competencies: [...state.competencies, ...payload.competencies],
			};

		case DELETE_COMPETENCY:
			return {
				...state,
				competencies: [
					...state.competencies.filter((evt) => evt.id !== payload),
				],
			};

		case CLEAR_COMPETENCY:
			return {
				...state,
				competencies: [],
			};

		case FETCH_PAYDOCUS:
			return {
				...state,
				paydocus: [...payload.paydocus],
			};
		case CLEAR_PAYDOCU:
			return {
				...state,
				paydocus: [],
			};
		case DELETE_PAYDOCU:
			return {
				...state,
				paydocus: [...state.paydocus.filter((evt) => evt.id !== payload)],
			};
		case FETCH_LAST_MONTH_PAYDOCUS:
			return {
				...state,
				lastMonthPaydocus: [...payload.lastMonthPaydocus],
			};
		case CLEAR_LAST_MONTH_PAYDOCU:
			return {
				...state,
				lastMonthPaydocus: [],
			};
		case DELETE_LAST_MONTH_PAYDOCU:
			return {
				...state,
				lastMonthPaydocus: [
					...state.lastMonthPaydocus.filter((evt) => evt.id !== payload),
				],
			};
		case LISTEN_TO_SELECTED_PAYDOCU:
			return {
				...state,
				selectedPaydocu: payload,
			};
		case SEND_PAYDOCUID:
			return {
				...state,
				paydocuId: payload,
			};
		case SEND_PAYDOCUCOPY:
			return {
				...state,
				paydocuCopy: payload,
			};
		case SEND_PAYDOCUFIX:
			return {
				...state,
				paydocuFix: payload,
			};
		case FINAL_RETIRE_HEALTHCARE_RECAL_FEE:
			return {
				...state,
				finalRetireHealthCareRecalFee: payload,
			};
		case FINAL_RETIRE_OLDCARE_RECAL_FEE:
			return {
				...state,
				finalRetireOldCareRecalFee: payload,
			};
		case CLEAR_FINAL_RETIRE_HEALTHCARE_RECAL_FEE:
			return {
				...state,
				finalRetireHealthCareRecalFee: '',
			};
		case CLEAR_FINAL_RETIRE_OLDCARE_RECAL_FEE:
			return {
				...state,
				finalRetireOldCareRecalFee: '',
			};
		case SEND_PAYDOCU_COMPANYNAME:
			return {
				...state,
				companyName: payload,
			};
		case CLEAR_SELECTED_PAYDOCU:
			return {
				...state,
				selectedPaydocu: null,
			};
		case SET_FILTER_PAYDOCU:
			return {
				...state,
				filter: payload,
			};
		case SET_FILTER_CLIENT_INFO:
			return {
				...state,
				clientInfo: payload,
			};
		case SET_YEARMONTH_PAYDOCU:
			return {
				...state,
				yearMonth: payload,
			};

		case SET_PAYDOCU_WORKER_NAME:
			return {
				...state,
				paydocuWorkerName: payload,
			};

		case FETCH_COMPANY_PROGRAM_USE_CP:
			return {
				...state,
				companyProgramUseCPs: [
					...state.companyProgramUseCPs,
					...payload.companyProgramUseCPs,
				],
			};
		case FETCH_PAYMENTSINFO:
			return {
				...state,
				paymentsInfo: [...state.paymentsInfo, ...payload.paymentsInfo],
			};
		case CLEAR_PAYMENTSINFO:
			return {
				...state,
				paymentsInfo: [],
			};
		case SET_WORKDATES_PAYDOCU:
			return {
				...state,
				workDates: payload,
			};
		case CLEAR_WORKDATES_PAYDOCU:
			return {
				...state,
				workDates: [],
			};
		case FETCH_WORKER_USER_COMPANY_DATA:
			return {
				...state,
				workerUserCompanyData: payload,
			};
		case CLEAR_WORKER_USER_COMPANY_DATA:
			return {
				...state,
				workerUserCompanyData: [],
			};
		case FETCH_PAYDOCUS_ANNUALLEAVES:
			return {
				...state,
				paydocusAnnualLeaves: [
					...state.paydocusAnnualLeaves,
					...(payload.paydocusAnnualLeaves || []),
				],
			};
		case CLEAR_PAYDOCU_ANNUALLEAVES:
			return {
				...state,
				paydocusAnnualLeaves: [],
			};
		default:
			return state;
	}
}
