import React from 'react';

function ClientRDaylyWorkerMonthWageTableWageInfoNoti() {
	return (
		<div style={{ padding: '10px', lineHeight: '1.6' }}>
			<div>
				* 본 임금명세서는 2021년 11월 19일 이후 시행되는 근로기준법
				제48조(임금명세서) 제2항 및 동법 시행령 제27조의 2(임금명세서의
				기재사항)에 따른{' '}
				<span style={{ fontSize: '1.1rem', color: 'blue' }}>
					기재사항을 안내한 명세서
				</span>
				입니다.
			</div>
			<div>
				*{' '}
				<span style={{ fontSize: '1.1rem', color: 'blue' }}>
					중도입퇴사 일할계산
				</span>
				과 세전 임금 및 세금, 4대보험 금액, 세후 임금은 모두 자동계산됩니다.
			</div>
			<div>
				* 추가 연장/야간/휴일 시간, 지각, 조퇴, 외출 시간 등이 있는 경우{' '}
				<span style={{ fontSize: '1.1rem', color: 'blue' }}>
					일수와 시간만 입력하시면 일할계산 등은 자동계산
				</span>
				됩니다.
			</div>
			<br />
		</div>
	);
}

export default ClientRDaylyWorkerMonthWageTableWageInfoNoti;
