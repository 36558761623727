import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { Table } from 'semantic-ui-react';
import {
	calculateDaySums,
	calculateTotalSum,
	calculateWeeks,
	getWeek,
	roundUp2,
	sumOfWeek,
} from '../../../../../app/common/util/util';
import { clearWorkDatesPaydocu } from '../../../../companyManage/companyActions';

function WeekNumberInput({
	selectedClientReq4In,
	targetMonth,
	targetYear,
	setWorkDatesPaydocu,
	dispatch,
	workDates,
	setMonthTotalWorkTime,
	setMonthTotalWorkTimeDays,
	filteredSelectedDays,
	setFilteredSelectedDays,
	selectedPaydocu,
}) {
	const [month, setMonth] = useState(targetMonth);
	const [year, setYear] = useState('');
	const [weeks, setWeeks] = useState([]);
	const [weekInputs, setWeekInputs] = useState({});
	const [warning, setWarning] = useState(false);
	const [selectedDays, setSelectedDays] = useState(workDates || []);

	useEffect(() => {
		// Initialize selectedDays based on selectedPaydocu
		dispatch(clearWorkDatesPaydocu([]));

		if (
			selectedPaydocu?.workerInfo?.workerId === selectedClientReq4In?.id &&
			selectedPaydocu?.workerInfo?.workDates[0]?.month === targetMonth
		) {
			const initialSelectedDays = selectedPaydocu.workerInfo.workDates;
			setSelectedDays(initialSelectedDays);

			if (initialSelectedDays.length > 0) {
				setMonth(initialSelectedDays[0]?.month);
				setYear(initialSelectedDays[0]?.year);
			}
		}
	}, [
		selectedPaydocu,
		selectedClientReq4In,
		targetMonth,
		dispatch,
		setWorkDatesPaydocu,
	]);

	useEffect(() => {
		// Initialize weekInputs based on selectedDays using weekNumber
		const newWeekInputs = selectedDays?.reduce(
			(result, { weekNumber, dayIndex, value }) => {
				if (!result[weekNumber]) result[weekNumber] = {};
				result[weekNumber][dayIndex] = value;
				return result;
			},
			{}
		);
		setWeekInputs(newWeekInputs);
	}, [selectedDays]);

	useEffect(() => {
		setMonth(targetMonth);
		setYear(targetYear);
		if (workDates.length > 0) {
			setSelectedDays(workDates);
		}
	}, [targetMonth, targetYear, workDates]);

	useEffect(() => {
		const newSelectedDays = selectedDays.filter(
			(day) => day?.month === targetMonth
		);
		setFilteredSelectedDays(newSelectedDays);

		// Initialize weekInputs based on newSelectedDays
		const newWeekInputs = newSelectedDays?.reduce(
			(result, { weekNumber, dayIndex, value }) => {
				if (!result[weekNumber]) result[weekNumber] = {};
				result[weekNumber][dayIndex] = value;
				return result;
			},
			{}
		);
		setWeekInputs(newWeekInputs);
	}, [targetMonth, selectedDays, setFilteredSelectedDays]);

	useEffect(() => {
		const newWeeks = calculateWeeks(month, year);
		setWeeks(newWeeks);
	}, [month, year]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		dispatch(setWorkDatesPaydocu(selectedDays));
	}, [setWorkDatesPaydocu, selectedDays, dispatch]);

	useEffect(() => {
		let totalSum = 0;
		selectedDays?.forEach(({ value }) => {
			totalSum += Number(value) || 0;
		});
		setMonthTotalWorkTime(totalSum);
		setMonthTotalWorkTimeDays(totalSum);
	}, [setMonthTotalWorkTime, selectedDays, setMonthTotalWorkTimeDays]);

	const handleInputChange = (event, weekIndex, dayIndex, weekNumber) => {
		const value = event.target.value || 0;
		const actualDate = moment(
			new Date(year, month - 1, weeks[weekIndex]?.[dayIndex]?.id)
		).format('D');

		if (event.target.value > 8) {
			setWarning(true);
			event.target.value = 8; // Optionally, you can also limit the value to 8
		} else {
			setWarning(false);
		}

		setWeekInputs((prevState = {}) => {
			const newState = { ...prevState };
			if (!newState[weekNumber]) newState[weekNumber] = {};
			newState[weekNumber][dayIndex] = value;

			// Calculate sumOfWorkHours after updating the state
			const sumOfWorkHours = Object.values(newState[weekNumber]).reduce(
				(a, b) => +a + +b,
				0
			);

			setSelectedDays((prevSelectedDays = []) => {
				const existingIndex = prevSelectedDays.findIndex(
					(day) => day.weekNumber === weekNumber && day.dayIndex === dayIndex
				);
				const weekNumberComputed = getWeek(
					new Date(year, month - 1, actualDate)
				);

				if (value > 0) {
					// If the day already exists in selectedDays, update its value
					if (existingIndex !== -1) {
						const updatedSelectedDays = [...prevSelectedDays];
						updatedSelectedDays[existingIndex] = {
							weekNumber: weekNumberComputed,
							sumOfWorkHours,
							dayIndex,
							value,
							date: actualDate,
							month,
							year,
						}; // Add actual date and weekNumber
						return updatedSelectedDays;
					}
					// If the day does not exist in selectedDays, add it
					else {
						return [
							...prevSelectedDays,
							{
								weekNumber: weekNumberComputed,
								sumOfWorkHours,
								dayIndex,
								value,
								date: actualDate,
								month,
								year,
							},
						]; // Add actual date and weekNumber
					}
				} else if (existingIndex !== -1) {
					// If the value is less than 1 and the day is in selectedDays, remove it
					const updatedSelectedDays = [...prevSelectedDays];
					updatedSelectedDays.splice(existingIndex, 1);
					return updatedSelectedDays;
				}
				// If none of the above conditions are met, return the previous selectedDays
				return prevSelectedDays;
			});

			return newState;
		});
	};
	const daySums = calculateDaySums(weekInputs);
	const totalSum = calculateTotalSum(daySums);

	const padding = '0.22rem';
	const tableLine = '1px solid rgba(0, 0, 0, 0.1)';

	return (
		<div>
			{warning ? (
				<span style={{ color: 'red', fontSize: '1.2rem', margin: '10px' }}>
					1일 8시간이하로 입력하세요.
				</span>
			) : (
				<span style={{ color: 'blue', fontSize: '1rem', marginBottom: '10px' }}>
					근무한{' '}
					<span style={{ fontSize: '1.2rem', fontWeight: 'bold' }}>
						{' '}
						모든 평일, 휴무일, 휴일에 대해{' '}
						<span style={{ color: 'red' }}>1일 8시간까지</span> 입력{' '}
						<span style={{ color: 'red' }}>[휴게시간 제외]</span>
					</span>
					하시고, <br />
					연장시간, 휴일시간 등은 하단 2-1. 추가근무시간에 입력하세요
				</span>
			)}
			<table style={{ textAlign: 'center' }}>
				<thead>
					<tr>
						<th>{month}월</th>
						<th>일</th>
						<th>월</th>
						<th>화</th>
						<th>수</th>
						<th>목</th>
						<th>금</th>
						<th>토</th>
						<th>합계</th>
					</tr>
				</thead>
				<tbody>
					{weeks.map((week, weekIndex) => {
						const weekNumber = getWeek(
							new Date(year, month - 1, week[0]?.id || 1)
						);

						return (
							<tr key={`week-${weekNumber}`}>
								<td>{weekNumber}주</td>
								{week.map((option, dayIndex) => (
									<td
										key={option ? option.id : `empty-${dayIndex}`}
										style={{ border: tableLine, padding: padding }}>
										{option ? (
											<label>
												{dayIndex === 0 ? option.name : `${option.id}`}
												<br />
												<input
													type='number'
													min={0}
													max={8}
													step='any'
													style={{
														marginLeft: '5px',
														width: '50px',
														textAlign: 'right',
													}}
													value={
														weekInputs[weekNumber]
															? weekInputs[weekNumber][dayIndex] || ''
															: ''
													}
													onChange={(event) =>
														handleInputChange(
															event,
															weekIndex,
															dayIndex,
															weekNumber
														)
													}
												/>
											</label>
										) : null}
									</td>
								))}
								{/* Add empty cells if the last day of the week is not Sunday */}
								{Array(7 - week.length)
									.fill()
									.map((_, i) => (
										<td
											key={`empty-${week.length + i}`}
											style={{ border: tableLine, padding: padding }}></td>
									))}
								<td style={{ border: tableLine, padding: padding }}>
									{roundUp2(sumOfWeek(weekNumber, weekInputs).toString())}
								</td>
							</tr>
						);
					})}

					<tr>
						<td style={{ textAlign: 'right' }}>합계:</td>
						<td>{roundUp2(daySums[0])}</td>
						<td>{roundUp2(daySums[1])}</td>
						<td>{roundUp2(daySums[2])}</td>
						<td>{roundUp2(daySums[3])}</td>
						<td>{roundUp2(daySums[4])}</td>
						<td>{roundUp2(daySums[5])}</td>
						<td>{roundUp2(daySums[6])}</td>
						<td>{roundUp2(totalSum)}</td>
					</tr>
					<Table.Row>
						<Table.Cell colSpan='9' style={{ color: 'blue' }}>
							{`근무일 : ${filteredSelectedDays
								?.map(({ date, value }) => (date ? date : ''))
								.join(', ')} [총 ${filteredSelectedDays.length ?? 0}일]`}
						</Table.Cell>
					</Table.Row>
				</tbody>
			</table>
		</div>
	);
}

export default WeekNumberInput;
