// Client side

export const FETCH_COMPANIES = 'FETCH_COMPANIES';
export const CLEAR_COMPANY = 'CLEAR_COMPANY';
export const DELETE_COMPANY = 'DELETE_COMPANY';

export const FETCH_WORKERS_OUT = 'FETCH_WORKERS_OUT';
export const CLEAR_WORKER_OUT = 'CLEAR_WORKER_OUT';
export const DELETE_WORKER_OUT = 'DELETE_WORKER_OUT';

export const FETCH_WORKERS_IN = 'FETCH_WORKERS_IN';
export const CLEAR_WORKER_IN = 'CLEAR_WORKER_IN';
export const DELETE_WORKER_IN = 'DELETE_WORKER_IN';

export const FETCH_COMPETENCIES = 'FETCH_COMPETENCIES';
export const CLEAR_COMPETENCY = 'CLEAR_COMPETENCY';
export const DELETE_COMPETENCY = 'DELETE_COMPETENCY';

export const FETCH_PAYDOCUS = 'FETCH_PAYDOCUS';
export const CLEAR_PAYDOCU = 'CLEAR_PAYDOCU';
export const DELETE_PAYDOCU = 'DELETE_PAYDOCU';

export const FETCH_LAST_MONTH_PAYDOCUS = 'FETCH_LAST_MONTH_PAYDOCUS';
export const CLEAR_LAST_MONTH_PAYDOCU = 'CLEAR_LAST_MONTH_PAYDOCU';
export const DELETE_LAST_MONTH_PAYDOCU = 'DELETE_LAST_MONTH_PAYDOCU';

export const SEND_PAYDOCUID = 'SEND_PAYDOCUID';
export const SEND_PAYDOCUCOPY = 'SEND_PAYDOCUCOPY';
export const SEND_PAYDOCUFIX = 'SEND_PAYDOCUFIX';

export const LISTEN_TO_SELECTED_PAYDOCU = 'LISTEN_TO_SELECTED_PAYDOCU';
export const CLEAR_SELECTED_PAYDOCU = 'CLEAR_SELECTED_PAYDOCU';
export const SET_FILTER_PAYDOCU = 'SET_FILTER_PAYDOCU';
export const SET_YEARMONTH_PAYDOCU = 'SET_YEARMONTH_PAYDOCU';

export const SET_FILTER_CLIENT_INFO = 'SET_FILTER_CLIENT_INFO';

export const FETCH_PAYMENTSINFO = 'FETCH_PAYMENTSINFO';
export const FETCH_COMPANY_PROGRAM_USE_CP = 'FETCH_COMPANY_PROGRAM_USE_CP';
export const CLEAR_PAYMENTSINFO = 'CLEAR_PAYMENTSINFO';

export const SET_WORKDATES_PAYDOCU = 'SET_WORKDATES_PAYDOCU';
export const CLEAR_WORKDATES_PAYDOCU = 'CLEAR_WORKDATES_PAYDOCU';

export const SEND_PAYDOCU_COMPANYNAME = 'SEND_PAYDOCU_COMPANYNAME';

export const FINAL_RETIRE_HEALTHCARE_RECAL_FEE =
	'FINAL_RETIRE_HEALTHCARE_RECAL_FEE';
export const FINAL_RETIRE_OLDCARE_RECAL_FEE = 'FINAL_RETIRE_OLDCARE_RECAL_FEE';

export const SET_PAYDOCU_WORKER_NAME = 'SET_PAYDOCU_WORKER_NAME';

export const CLEAR_FINAL_RETIRE_HEALTHCARE_RECAL_FEE =
	'CLEAR_FINAL_RETIRE_HEALTHCARE_RECAL_FEE';
export const CLEAR_FINAL_RETIRE_OLDCARE_RECAL_FEE =
	'CLEAR_FINAL_RETIRE_OLDCARE_RECAL_FEE';

export const SUPER_ADMIN_FOR_FREE = 'SUPER_ADMIN_FOR_FREE';

// 직원 회원 데이터 가져오기
export const FETCH_WORKER_USER_COMPANY_DATA = 'FETCH_WORKER_USER_COMPANY_DATA';
export const CLEAR_WORKER_USER_COMPANY_DATA = 'CLEAR_WORKER_USER_COMPANY_DATA';

export const FETCH_AGENT_COMPANIES = 'FETCH_AGENT_COMPANIES';

export const FETCH_PAYDOCUS_ANNUALLEAVES = 'FETCH_PAYDOCUS_ANNUALLEAVES';
export const CLEAR_PAYDOCU_ANNUALLEAVES = 'CLEAR_PAYDOCU_ANNUALLEAVES';
