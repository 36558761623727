import React, { useState, useEffect, useCallback } from 'react';
import {
	calculateWeeks,
	getWeek,
	roundUp2,
	sumOfWeek,
} from '../../../../../app/common/util/util';

export default function WeekNumberInputLastMonth({
	targetMonth,
	targetYear,
	paydocus,
	workerId,
	setPreviousWorkDates,
}) {
	const [month, setMonth] = useState('');
	const [year, setYear] = useState('');
	const [weeks, setWeeks] = useState([]);
	const [weekInputs, setWeekInputs] = useState([]);
	const [selectedDaysLastMonth, setSelectedDaysLastMonth] = useState([]);

	// 전달 workDates 추출하는 함수
	const getPreviousMonthWorkDates = useCallback(() => {
		let year = targetYear;
		let month = targetMonth - 1;
		if (month < 1) {
			year -= 1;
			month = 12;
		}
		const lastYearMonth = `${year.toString()}${month
			.toString()
			.padStart(2, '0')}`;
		const lastMonthPayDocu = paydocus.find(
			(paydocu) =>
				paydocu.workerInfo?.workerId === workerId &&
				paydocu.info.yearMonth === lastYearMonth
		);
		return {
			lastYearMonth,
			lastMonthWorkDates: lastMonthPayDocu?.workerInfo?.workDates || [],
		};
	}, [targetMonth, targetYear, paydocus, workerId]);

	useEffect(() => {
		const { lastYearMonth, lastMonthWorkDates } = getPreviousMonthWorkDates();
		setMonth(lastYearMonth.substring(4, 6));
		setYear(lastYearMonth.substring(0, 4));
		setSelectedDaysLastMonth(lastMonthWorkDates);
		setPreviousWorkDates(lastMonthWorkDates);
	}, [getPreviousMonthWorkDates, setPreviousWorkDates]);

	useEffect(() => {
		setWeeks(calculateWeeks(month, year));
	}, [month, year]);
	useEffect(() => {
		const newWeekInputs = selectedDaysLastMonth.reduce(
			(result, { weekNumber, dayIndex, value }) => {
				if (!result[weekNumber]) result[weekNumber] = {};
				result[weekNumber][dayIndex] = value;
				return result;
			},
			{}
		);
		setWeekInputs(newWeekInputs);
	}, [selectedDaysLastMonth]);

	const padding = '0.22rem';
	const tableLine = '1px solid rgba(0, 0, 0, 0.1)';

	return (
		<div>
			<span style={{ color: 'blue', fontSize: '1rem', marginBottom: '10px' }}>
				주휴수당 등 계산을 위한 참고용 전월 근로내역입니다
			</span>
			<br />
			<br />
			<table style={{ textAlign: 'center' }}>
				<thead>
					<tr>
						<th>{month}월</th>
						<th>일</th>
						<th>월</th>
						<th>화</th>
						<th>수</th>
						<th>목</th>
						<th>금</th>
						<th>토</th>
						<th>계</th>
					</tr>
				</thead>
				<tbody>
					{weeks.map((week, weekIndex) => {
						const weekNumber = getWeek(
							new Date(year, month - 1, week[0]?.id || 1)
						);

						return (
							<tr key={`week-${weekNumber}-${weekIndex}`}>
								<td>{weekNumber}주</td>
								{week.map((option, dayIndex) => (
									<td
										key={option ? option.id : `empty-${dayIndex}`}
										style={{ border: tableLine, padding: padding }}>
										{option ? (
											<label>
												{dayIndex === 0 ? option.name : `${option.id}`}
												<br />
												<input
													type='number'
													disabled
													min={0}
													max={8}
													step='any'
													style={{
														marginLeft: '5px',
														width: '50px',
														textAlign: 'right',
													}}
													value={
														weekInputs[weekNumber]
															? weekInputs[weekNumber][dayIndex] || ''
															: ''
													}
												/>
											</label>
										) : null}
									</td>
								))}
								{/* Add empty cells if the last day of the week is not Sunday */}
								{Array(7 - week.length)
									.fill()
									.map((_, i) => (
										<td
											key={`empty-${week.length + i}`}
											style={{ border: tableLine, padding: padding }}></td>
									))}
								<td style={{ border: tableLine, padding: padding }}>
									{roundUp2(sumOfWeek(weekNumber, weekInputs).toString())}
								</td>
							</tr>
						);
					})}
				</tbody>
			</table>
		</div>
	);
}
