// ImageGalleryModal.js
import React from 'react';
import { Modal, Grid, Image, Header } from 'semantic-ui-react';

const ImageGalleryModal = ({ open, images, onClose, onImageSelect }) => {
	const formatDate = (isoString) => {
		const date = new Date(isoString);
		return `${date.getFullYear()}년 ${String(date.getMonth() + 1).padStart(
			2,
			'0'
		)}월 ${String(date.getDate()).padStart(2, '0')}일 ${String(
			date.getHours()
		).padStart(2, '0')}시 ${String(date.getMinutes()).padStart(
			2,
			'0'
		)}분 ${String(date.getSeconds()).padStart(2, '0')}초`;
	};

	return (
		<Modal open={open} onClose={onClose} closeIcon size='large'>
			<Modal.Header>이미지 갤러리</Modal.Header>
			<Modal.Content scrolling>
				<Grid columns={3} stackable>
					{images.map((item, index) => (
						<Grid.Column key={index}>
							<div
								onClick={() => onImageSelect(item)}
								style={{ cursor: 'pointer', marginBottom: '1em' }}>
								<Image src={item.imageUrl} fluid />
								<Header
									as='h5'
									textAlign='center'
									style={{ marginTop: '0.5em' }}>
									{`Saved & sent : ${formatDate(item.saved)}` || '등록일 없음'}
								</Header>
							</div>
						</Grid.Column>
					))}
				</Grid>
			</Modal.Content>
		</Modal>
	);
};

export default ImageGalleryModal;
