import React from 'react';
import MyNumberInput from '../../../../../app/common/form/MyNumberInput';
import MyTextInput from '../../../../../app/common/form/MyTextInput';

import {
	cTFCarWageLimit,
	cTFChildWageLimit,
	cTFMealWageLimit,
	cTFStudyWageLimit,
} from '../../../../../app/common/util/util';
import { useDispatch } from 'react-redux';
import { openModal } from '../../../../../app/common/modals/modalReducer';
import { Icon, Label } from 'semantic-ui-react';

export default function CrwfWorkerBasicInfoMonthWageOrd({ companyName }) {
	const dispatch = useDispatch();

	function workerRegStep3_2YTOpen() {
		dispatch(openModal({ modalType: 'WorkerRegStep3_2' }));
	}

	const cOWTitleName = (companyName) => {
		return companyName?.includes('어린이집') ? (
			<span>
				(7-1) 직책수당(관리감독)수당(원)
				<span style={{ color: 'blue' }}>
					{' '}
					((어린이집 - 원장직책급, 주임, 팀장수당))
				</span>
			</span>
		) : (
			'(7-1) 직책수당(관리감독)수당(원)'
		);
	};

	const childCompany = companyName?.includes('어린이집') ? true : false;

	return (
		<>
			<div
				style={{
					display: 'flex',
					alignItems: 'center',
					alignContent: 'flex-end',
					width: '100%',
					marginBottom: '30px',
					marginTop: '30px',
					fontSize: '17px',
				}}>
				<span style={{ color: 'red' }}>* </span>
				<span style={{ color: 'blue' }}>
					<span style={{ color: 'teal', fontSize: '16px' }}>
						정기적, 일률적
					</span>
					으로 지급하는 식대, 직책수당 등{' '}
					<span style={{ color: 'teal' }}>월 통상임금</span> 구성항목 있는 경우
				</span>
				<span style={{ fontSize: '8px' }}>
					(설명은 오른쪽 동영상을 참조하세요.)
				</span>
				<span
					style={{
						position: 'absolute',
						right: 0,
						marginRight: '10px',
						cursor: 'pointer',
					}}>
					<Label
						style={{ color: 'red' }}
						onClick={() => workerRegStep3_2YTOpen()}>
						<Icon size='big' name='youtube' />
						통상임금설정
					</Label>
				</span>
			</div>
			<div
				style={{
					display: 'flex',
					flexWrap: 'wrap',
					justifyContent: 'flex-start',
					alignItems: 'center',
					alignContent: 'flex-end',
				}}>
				<div
					style={{
						width: '100%',
						marginBottom: '10px',
						marginTop: '50px',
						fontSize: '13px',
					}}>
					●<span style={{ color: 'teal' }}>비과세</span> 월 통상임금 있는 경우
				</div>
				<>
					<div className='margin' style={{ width: '33%', marginRight: '3px' }}>
						<MyNumberInput
							label='(6-1) 현물 대신 지급하는 식대(원)'
							name='taxFreeWages.mealWage'
							placeholder={cTFMealWageLimit}
							autoComplete='off'
						/>
					</div>
					<div className='margin' style={{ width: '33%', marginRight: '3px' }}>
						<MyNumberInput
							label='(6-2) 직원소유 차량유지비(원)'
							name='taxFreeWages.carWage'
							placeholder={cTFCarWageLimit}
							autoComplete='off'
						/>
					</div>
					<div className='margin' style={{ width: '33%', marginRight: '3px' }}>
						<MyNumberInput
							label='(6-3) 6세미만 육아수당(원)'
							name='taxFreeWages.childWage'
							placeholder={cTFChildWageLimit}
							autoComplete='off'
						/>
					</div>
					<div className='margin' style={{ width: '33%', marginRight: '3px' }}>
						<MyNumberInput
							label='(6-4) 연구소 소속 연구수당(원)'
							name='taxFreeWages.studyWage'
							placeholder={cTFStudyWageLimit}
							autoComplete='off'
						/>
					</div>
					<div className='margin' style={{ width: '33%', marginRight: '3px' }}>
						<MyNumberInput
							label='(6-5) 나머지 비과세 소계(원)'
							name='taxFreeWages.etc'
							placeholder='정확한 소득세법상 비과세한도액 입력'
							autoComplete='off'
						/>
					</div>
					<div
						className='margin'
						style={{ width: '99.5%', marginRight: '3px' }}>
						<MyTextInput
							label='(6-5-1) 나머지 바과세 상세내역'
							placeholder=''
							name='taxFreeWages.etcComment'
							autoComplete='off'
						/>
					</div>
				</>

				<div
					style={{
						width: '100%',
						marginBottom: '10px',
						marginTop: '50px',
						fontSize: '13px',
					}}>
					● <span style={{ color: 'teal' }}>직책수당 등</span> 월 통상임금 있는
					경우
				</div>
				<>
					<div className='margin' style={{ width: '33%', marginRight: '3px' }}>
						<MyNumberInput
							label={cOWTitleName(companyName)}
							name='ordinaryWage.title'
							placeholder='통상임금'
							autoComplete='off'
						/>
					</div>
					<div className='margin' style={{ width: '33%', marginRight: '3px' }}>
						<MyNumberInput
							label='(7-2) 직무수당(원)'
							name='ordinaryWage.job'
							placeholder='통상임금'
							autoComplete='off'
						/>
					</div>
					<div className='margin' style={{ width: '33%', marginRight: '3px' }}>
						<MyNumberInput
							label='(7-3) 자격/면허 수당(원)'
							name='ordinaryWage.license'
							placeholder='통상임금'
							autoComplete='off'
						/>
					</div>
					<div className='margin' style={{ width: '33%', marginRight: '3px' }}>
						<MyNumberInput
							label='(7-4) 월 고정 상여금(원)'
							name='ordinaryWage.monthlyBonus'
							placeholder='통상임금'
							autoComplete='off'
						/>
					</div>
					<div className='margin' style={{ width: '33%', marginRight: '3px' }}>
						<MyNumberInput
							label='(7-5) 근속수당(원)'
							name='ordinaryWage.longTerm'
							placeholder='통상임금'
							autoComplete='off'
						/>
					</div>
					<div className='margin' style={{ width: '33%', marginRight: '3px' }}>
						<MyNumberInput
							label='(7-6) 매월 고정적 기타 임금 소계(원)'
							name='ordinaryWage.etc'
							placeholder='통상임금'
							autoComplete='off'
						/>
					</div>
					<div
						className='margin'
						style={{ width: '99.5%', marginRight: '3px' }}>
						<MyTextInput
							label='(7-6-1) 매월 고정적 기타 임금 상세내역'
							placeholder=''
							name='ordinaryWage.etcComment'
							autoComplete='off'
						/>
					</div>
					{childCompany && (
						<>
							<div
								style={{
									width: '100%',
									marginBottom: '10px',
									marginTop: '50px',
									fontSize: '13px',
								}}>
								● <span style={{ color: 'teal' }}>어린이집 통상임금 설정</span>
							</div>
							<div
								className='margin'
								style={{ width: '33%', marginRight: '3px' }}>
								<MyNumberInput
									label='(8-1) 관리 및 연구수당(원)'
									name='ordinaryWage.childManageStudy'
									placeholder='통상임금'
									autoComplete='off'
								/>
							</div>
							<div
								className='margin'
								style={{ width: '33%', marginRight: '3px' }}>
								<MyNumberInput
									label='(8-2) 연구수당(원)'
									name='ordinaryWage.childStudy'
									placeholder='통상임금'
									autoComplete='off'
								/>
							</div>
							<div
								className='margin'
								style={{ width: '33%', marginRight: '3px' }}>
								<MyNumberInput
									label='(8-3) 취약보육수당(원)'
									name='ordinaryWage.childCare'
									placeholder='통상임금'
									autoComplete='off'
								/>
							</div>
							<div
								className='margin'
								style={{ width: '33%', marginRight: '3px' }}>
								<MyNumberInput
									label='(8-4) 활동보조수당(원)'
									name='ordinaryWage.childActivity'
									placeholder='통상임금'
									autoComplete='off'
								/>
							</div>
						</>
					)}
				</>
			</div>
		</>
	);
}
