import { getAuth } from 'firebase/auth';
import { ref, uploadBytes, getDownloadURL, getStorage } from 'firebase/storage';
import { collection, addDoc, getFirestore } from 'firebase/firestore';
import { v4 as uuidv4 } from 'uuid';
import html2canvas from 'html2canvas';

// Firebase 인스턴스를 외부에서 주입하기 위해 파라미터 추가
const captureAndUpload = async (
	documentId,
	workerId,
	workerName,
	workerEmail,
	companyId,
	companyName,
	yearMonth = null,
	isCompanyComformedTime = null,
	token,
	history,
	docPath,
	divId,
	finalAddress,
	storageName
) => {
	const db = getFirestore();
	const storage = getStorage();

	const controller = new AbortController();
	const signal = controller.signal;

	const auth = getAuth();
	const user = auth.currentUser;

	if (!user) {
		alert('🚨 Firebase Storage: 인증되지 않은 사용자입니다.');
		return null;
	}

	// 페이지 이동 및 대기 처리
	history.push(`/${docPath}/${documentId}?token=${token}`);

	// 🔹 MutationObserver로 요소 렌더링 감지 후 대기
	await new Promise((resolve) => {
		const observer = new MutationObserver((mutations, observer) => {
			const target = document.getElementById(divId);
			if (target) {
				observer.disconnect(); // 관찰 중지
				resolve(); // 대기 종료 후 다음 단계 진행
			}
		});

		observer.observe(document.body, { childList: true, subtree: true });
	});

	try {
		const element = document.getElementById(divId);
		if (!element) {
			throw new Error(
				`🚨 wageTable 요소를 찾을 수 없습니다 (ID: ${documentId})`
			);
		}

		// 캔버스로 렌더링 후 Blob 생성
		const canvas = await html2canvas(element, { signal });
		const imageBlob = await new Promise((resolve) =>
			canvas.toBlob(resolve, 'image/png')
		);
		if (!imageBlob) throw new Error('🚨 이미지 Blob 생성 실패');

		const fileName = `${storageName}Images/${documentId}_${uuidv4()}.png`;
		const storageRef = ref(storage, fileName);

		// Firebase Storage에 업로드
		const metadata = {
			contentType: 'image/png',
			customMetadata: {
				uploadedBy: user.uid,
				companyId,
			},
		};

		await uploadBytes(storageRef, imageBlob, metadata, { signal });
		const imageUrl = await getDownloadURL(storageRef);

		// Firestore에 메타데이터 저장
		await addDoc(collection(db, `${storageName}StorageFiles`), {
			storageName,
			documentId,
			workerId,
			workerName,
			workerEmail,
			companyId,
			companyName,
			imageUrl,
			uploadedBy: user.uid,
			uploadTimestamp: new Date().toISOString(),
			// 임금명세서 추가정보
			isCompanyComformedTime,
			yearMonth,
		});

		return imageUrl;
	} catch (error) {
		if (signal.aborted) {
			console.warn('🚨 작업이 취소되었습니다:', error.message);
		} else {
			console.error('🚨 이미지 업로드 또는 Firestore 저장 실패:', error);
		}
		return null;
	} finally {
		history.push(`/${finalAddress}`);
	}
};

export default captureAndUpload;
