import React, { useState, useEffect } from 'react';
import {
	calculateDaySums,
	calculateTotalSum,
	calculateWeeks,
	getWeek,
	roundUp2,
	sumOfWeek,
	// roundUp2, sumOfWeek, calculateDaySums, calculateTotalSum
} from '../../../../../app/common/util/util';
import { Table } from 'semantic-ui-react';
import moment from 'moment';
// import moment from 'moment';
// import { Table } from 'semantic-ui-react';

function WeekNumberInputDayWorker({
	confirmedBasicTime,
	selectedClientReq4In,
	targetMonth,
	targetYear,
	setWorkDatesPaydocu,
	dispatch,
	workDates,
	setMonthTotalWorkTime,
	setMonthTotalWorkTimeDays,
	filteredSelectedDays,
	setFilteredSelectedDays,
	selectedPaydocu,
}) {
	const [month, setMonth] = useState(targetMonth);
	const [year, setYear] = useState('');
	const [weeks, setWeeks] = useState([]);
	const [weekInputs, setWeekInputs] = useState([]);
	const [selectedDays, setSelectedDays] = useState(workDates || []);
	const [checkedWeeks, setCheckedWeeks] = useState([]);
	console.log('weekInputs', weekInputs);
	useEffect(() => {
		if (
			selectedPaydocu?.workerInfo?.workerId === selectedClientReq4In?.id &&
			selectedPaydocu?.workerInfo?.workDates[0]?.month === targetMonth
		) {
			const initialSelectedDays = selectedPaydocu.workerInfo.workDates;
			setSelectedDays(initialSelectedDays);

			if (initialSelectedDays.length > 0) {
				setMonth(initialSelectedDays[0]?.month);
				setYear(initialSelectedDays[0]?.year);
			}
		}
	}, [selectedPaydocu, selectedClientReq4In, targetMonth]);

	useEffect(() => {
		// Initialize weekInputs based on selectedDays
		const newWeekInputs = selectedDays?.reduce(
			(result, { weekNumber, dayIndex, value }) => {
				if (!result[weekNumber]) result[weekNumber] = {};
				result[weekNumber][dayIndex] = value;
				return result;
			},
			{}
		);
		setWeekInputs(newWeekInputs);
	}, [selectedDays]);

	useEffect(() => {
		setMonth(targetMonth);
		setYear(targetYear);
		if (workDates.length > 0) {
			setSelectedDays(workDates);
		}
		// setWeekInputs([]); // 모든 주의 입력 상태 초기화
		// setCheckedWeeks([]); // 모든 체크된 주 초기화
	}, [targetMonth, targetYear, workDates]);

	useEffect(() => {
		const newSelectedDays = selectedDays.filter(
			(day) => day?.month === targetMonth
		);
		setFilteredSelectedDays(newSelectedDays);

		// Initialize weekInputs based on newSelectedDays
		const newWeekInputs = newSelectedDays?.reduce(
			(result, { weekNumber, dayIndex, value }) => {
				if (!result[weekNumber]) result[weekNumber] = {};
				result[weekNumber][dayIndex] = value;
				return result;
			},
			{}
		);
		setWeekInputs(newWeekInputs);
	}, [targetMonth, selectedDays, setFilteredSelectedDays]);

	const daySums = calculateDaySums(weekInputs);
	const totalSum = calculateTotalSum(daySums);

	useEffect(() => {
		let totalSum = 0;
		selectedDays?.forEach(({ value }) => {
			totalSum += Number(value) || 0;
		});
		setMonthTotalWorkTime(totalSum);
		setMonthTotalWorkTimeDays(totalSum);
	}, [setMonthTotalWorkTime, selectedDays, setMonthTotalWorkTimeDays]);

	useEffect(() => {
		const newWeeks = calculateWeeks(month, year);
		setWeeks(newWeeks);
	}, [month, year]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		dispatch(setWorkDatesPaydocu(selectedDays));
	}, [setWorkDatesPaydocu, selectedDays, dispatch]);

	useEffect(() => {
		let totalSum = 0;
		selectedDays?.forEach(({ value }) => {
			totalSum += Number(value) || 0;
		});
		setMonthTotalWorkTime(totalSum);
		setMonthTotalWorkTimeDays(totalSum);
	}, [setMonthTotalWorkTime, selectedDays, setMonthTotalWorkTimeDays]);

	const handleDayChange = (day, isChecked, weekIndex, dayIndex) => {
		if (!day) return; // day 객체가 유효하지 않을 경우 아무 작업도 수행하지 않습니다.
		const value = isChecked ? confirmedBasicTime : 0;
		const actualDate = moment(new Date(year, month - 1, day.id)).format('D');

		setWeekInputs((prevState) => {
			const newState = { ...prevState };
			if (!newState[weekIndex]) newState[weekIndex] = {};
			newState[weekIndex][dayIndex] = value;
			return newState;
		});

		setSelectedDays((prev) => {
			const existingIndex = prev.findIndex(
				(d) => d.weekIndex === weekIndex && d.dayIndex === dayIndex
			);
			const weekNumber = getWeek(new Date(year, month - 1, actualDate));

			if (isChecked) {
				const newDay = {
					id: day.id,
					weekIndex,
					dayIndex,
					value,
					date: actualDate,
					month,
					year,
					weekNumber,
				};
				if (existingIndex !== -1) {
					const updatedDays = [...prev];
					updatedDays[existingIndex] = newDay;
					return updatedDays;
				} else {
					return [...prev, newDay];
				}
			} else {
				return prev.filter(
					(d) => d.weekIndex !== weekIndex || d.dayIndex !== dayIndex
				);
			}
		});
	};

	const handleWeekChange = (weekIndex, isChecked) => {
		weeks[weekIndex].forEach((day, dayIndex) => {
			if (day) handleDayChange(day, isChecked, weekIndex, dayIndex);
		});
		setCheckedWeeks((prev) => {
			const newCheckedWeeks = new Set(prev);
			if (isChecked) {
				newCheckedWeeks.add(weekIndex);
			} else {
				newCheckedWeeks.delete(weekIndex);
			}
			return Array.from(newCheckedWeeks);
		});
	};

	const handleAllChange = (isChecked) => {
		weeks.forEach((week, weekIndex) => {
			handleWeekChange(weekIndex, isChecked);
		});
	};

	const handleDayOfWeekChange = (dayOfWeek, isChecked) => {
		weeks.forEach((week, weekIndex) => {
			week.forEach((day, dayIndex) => {
				if (day && new Date(year, month - 1, day.id).getDay() === dayOfWeek) {
					handleDayChange(day, isChecked, weekIndex, dayIndex);
				}
			});
		});
	};

	const sortedSelectedDays = filteredSelectedDays.sort((a, b) => {
		const dateA = new Date(a.year, a?.month - 1, a.date);
		const dateB = new Date(b.year, b?.month - 1, b.date);
		return dateA - dateB;
	});

	const isDayOfWeekChecked = (dayOfWeek) => {
		return weeks.every((week) =>
			week
				.filter(
					(day) =>
						day && new Date(year, month - 1, day.id).getDay() === dayOfWeek
				)
				.every((day) =>
					selectedDays.some((d) => d.id === day.id && d.value > 0)
				)
		);
	};

	const padding = '0.22rem';
	const tableLine = '1px solid rgba(0, 0, 0, 0.1)';

	return (
		<div>
			<table style={{ textAlign: 'center' }}>
				<thead>
					<tr>
						<th>{month}월</th>
						<th>
							<label>
								<input
									type='checkbox'
									onChange={(e) => handleAllChange(e.target.checked)}
									style={{ marginRight: '5px' }}
								/>
								전체(월)
							</label>
						</th>
						{['일', '월', '화', '수', '목', '금', '토'].map((day, index) => (
							<th key={day}>
								<label>
									<input
										type='checkbox'
										checked={isDayOfWeekChecked(index)}
										onChange={(e) =>
											handleDayOfWeekChange(index, e.target.checked)
										}
										style={{ marginRight: '5px' }}
									/>
									{day}
								</label>
							</th>
						))}
						<th>합계</th>
					</tr>
				</thead>
				<tbody>
					{weeks.map((week, weekIndex) => {
						const weekNumber = getWeek(
							new Date(year, month - 1, week[0]?.id || 1)
						);

						return (
							<tr key={`week-${weekIndex}`}>
								<td>
									{getWeek(new Date(year, month - 1, week[0] ? week[0].id : 1))}
									주
								</td>
								<td>
									<label>
										<input
											type='checkbox'
											checked={checkedWeeks?.includes(weekIndex)}
											onChange={(e) =>
												handleWeekChange(weekIndex, e.target.checked)
											}
											style={{ marginRight: '5px' }}
										/>
										선택
									</label>
								</td>
								{week.map((day, dayIndex) => (
									<td key={day ? day.id : `empty-${dayIndex}`}>
										{day ? (
											<label>
												<input
													type='checkbox'
													style={{ marginRight: '5px' }}
													// 여기서 selectedDays 배열을 확인하여 체크 상태를 결정합니다.
													checked={selectedDays.some((d) => d.id === day.id)}
													onChange={(e) =>
														handleDayChange(
															day,
															e.target.checked,
															weekIndex,
															dayIndex
														)
													}
												/>
												{day.id}
											</label>
										) : (
											''
										)}
									</td>
								))}
								{/* Add empty cells if the last day of the week is not Sunday */}
								{Array(7 - week.length)
									.fill()
									.map((_, i) => (
										<td
											key={`empty-${week.length + i}`}
											style={{ border: tableLine, padding: padding }}></td>
									))}
								<td style={{ border: tableLine, padding: padding }}>
									{roundUp2(sumOfWeek(weekNumber, weekInputs).toString())}
								</td>
							</tr>
						);
					})}
					<tr>
						<td colSpan={2} style={{ textAlign: 'right' }}>
							합계:
						</td>
						<td>{roundUp2(daySums[0])}</td>
						<td>{roundUp2(daySums[1])}</td>
						<td>{roundUp2(daySums[2])}</td>
						<td>{roundUp2(daySums[3])}</td>
						<td>{roundUp2(daySums[4])}</td>
						<td>{roundUp2(daySums[5])}</td>
						<td>{roundUp2(daySums[6])}</td>
						<td>{roundUp2(totalSum)}</td>
					</tr>
					<Table.Row>
						<Table.Cell colSpan='9' style={{ color: 'blue' }}>
							{`근무일 : ${sortedSelectedDays
								?.map(({ date, value }) => (date ? date : ''))
								.join(', ')} [총 ${sortedSelectedDays.length ?? 0}일]`}
						</Table.Cell>
					</Table.Row>
				</tbody>
			</table>
		</div>
	);
}

export default WeekNumberInputDayWorker;
