// ImageDetailModal.js
import React from 'react';
import { Modal, Image } from 'semantic-ui-react';

const ImageDetailModal = ({ open, image, onClose }) => {
	if (!image) return null;
	return (
		<Modal open={open} onClose={onClose} closeIcon size='large'>
			<Modal.Header>{image.yearMonth || '상세 이미지'}</Modal.Header>
			<Modal.Content image>
				<Image wrapped src={image.imageUrl} fluid />
			</Modal.Content>
		</Modal>
	);
};

export default ImageDetailModal;
