import React, { useState, useMemo, useEffect } from 'react';
import '../Roe1GeneralRules.css'; // CSS 파일 불러오기

const RuleSection = ({
	number,
	title,
	content,
	isOpen,
	toggleOpen,
	amended,
}) => {
	const formatContent = (content) => {
		if (!content) return null; // content가 undefined일 경우 null 반환

		let mainCount = 0; // 0부터 시작하여 CSS에서 올바르게 매핑될 수 있도록 설정

		return content.map((item, index) => {
			if (Array.isArray(item)) {
				return (
					<ol key={index} type='1'>
						{item.map((subItem, subIndex) => (
							<li
								key={subIndex}
								className='rule-sub-item'
								data-index={subIndex}>
								{subItem}
							</li>
						))}
					</ol>
				);
			} else {
				const formattedItem = item;
				return (
					<li key={index} className='rule-item' data-index={mainCount++}>
						{formattedItem}
					</li>
				);
			}
		});
	};

	return (
		<div>
			<div className='jau__title sectionup' onClick={toggleOpen}>
				{`제${number}조 ${title}`}
			</div>
			{isOpen && (
				<div className='jau__contents'>
					<ul>{formatContent(content)}</ul>
					{amended && (
						<div className='no-print'>
							<ul>
								{amended.map((amendment, i) => (
									<li
										key={i}
										style={{
											color: amendment?.includes('개정') ? 'red' : 'black',
										}}>
										{amendment}
									</li>
								))}
							</ul>
						</div>
					)}
				</div>
			)}
		</div>
	);
};

export default function Roe3WageRules({
	serviceStartDate,
	profile,
	authLevel,
	company,
	companyUn,
	companyGa,
	allOpen,
	previousSectionCount1,
	previousSectionCount2,
	setSectionCount,
}) {
	const sections = useMemo(() => {
		let baseSections = [
			{
				title: `급여제도`,
				content: [
					`임금은 월급제 또는 일급제로 지급한다.`,
					`월급제라 함은 사원에게 개별 근로계약서에 정해지는 시간에 대한 임금(주휴수당 포함)과 임금계약서에서 정해지는 임금을 매월 정해지는 일자에 지급하는 제도를 말한다.`,
					`${companyUn} 제2항에 따라 정해지는 근로시간이 연장, 야간, 휴일 근로를 포함하고 있는 경우 해당 약정연장근로수당, 약정야간근로수당, 약정휴일근로수당을 지급한다.`,
					`임금계약에 따라 제3항에 따른 약정수당이 법에 의하여 계산한 금액보다 낮은 경우 그 차액을 지급하고, 약정수당이 많은 경우 다음기의 임금계약에서 약정수당을 조정할 수 있다.`,
					`일급제라 함은 근로계약서에 정해지는 시간에 대한 임금(소정근로를 초과하는 경우 약정연장수당, 야간의 경우 약정야간수당 포함 가능)을 원칙적으로 당일 지급하는 제도를 말한다.`,
					`${company}의 자금 집행 계획에 따라 매월 특정일에 인건비가 집행되는 경우 사원이 동의한 경우, 일급 지급일을 특정일로 변경할 수 있다.`,
					`제5항의 일급제는 해당일 근로시간에 대한 근무일에 근로관계가 시작되고 소멸되므로 근로기준법 제55조의 주휴일 조항은 적용되지 않음이 원칙이되, 예외적으로 연속된 특정 7일 이내(월요일부터 기산할 필요 없음)에 근무일이 특정되면서 2일 이상 근무하면서 15시간 이상 근무하는 경우 해당 일당에 주휴수당 1일분(최대 1.6시간분)을 포함하여 지급할 수 있다.`,
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
			},
			{
				title: `연장·야간·휴일근로수당`,
				content: [
					`${companyUn} 업무상 필요한 경우 사원과 합의된 기타 연장근로와 야간근로(22시에서 익일 06시 사이의 근로) 및 휴일근로를 수행하도록 할 수 있다.`,
					`연장근로수당은 연장근로에 대한 정상임금과 가산수당으로 구성된다.`,
					`야간근로수당은 야간근로에 대한 정상임금과 가산수당으로 구성된다.`,
					`휴일근로수당은 휴일근로에 대한 정상임금과 가산수당으로 구성된다.`,
					`가산수당은 다음 각 호와 같다.`,
					[
						`연장근로 가산수당 : 통상시급의 100분의 50.`,
						`야간근로 가산수당 : 통상시급의 100분의 50.`,
						`8시간 이내의 휴일근로 가산수당 : 통상시급의 100분의 50.`,
						`8시간 초과하는 휴일근로 가산수당 : 통상시급의 100분의 100.`,
					],
					`관리감독자, 재량근로제 적용 사원 및 간주근로제 적용 사원의 경우 사원대표와의 서면합의로 연장, 야간 및 휴일근로를 정한 경우를 제외하고 본 조항을 적용하지 않는다.`,
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
			},
			{
				title: `휴업수당`,
				content: [
					`${company}의 귀책사유로 휴업하는 경우에 ${companyUn} 휴업기간 동안 그 사원에게 평균임금의 100분의 70 이상의 수당을 지급하여야 한다. 다만, 평균임금의 100분의 70에 해당하는 금액이 통상임금을 초과하는 경우에는 통상임금을 휴업수당으로 지급할 수 있다.`,
					`전염병에 걸림으로 인하여 그 사원이 출근할 수가 없거나 다른 사원의 건강에 영향을 미칠 우려가 있는 사원의 자가 격리에 따른 휴무가 행정지도에 따른 경우에는 제1항의 휴업수당을 지급한다.`,
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
			},
			{
				title: `통상임금`,
				content: [
					`통상임금은 사원의 1일 8시간, 1주 40시간 이내의 소정근로에 대해 정기적∙일률적으로 지급하는 임금을 말한다.`,
					`근로계약 및 임금계약으로 소정근로시간을 초과하는 연장근로, 야간근로, 휴일근로를 예정하여 발생하는 약정연장/약정야간/약정휴일 근로수당은 소정근로에 대한 대가가 아니므로 정기적∙일률적∙고정적으로 지급되더라도 통상임금에 산입되지 아니한다.`,
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
			},
			{
				title: `평균임금`,
				content: [
					`평균임금은 평균임금산정 사유 발생일 이전 3개월간에 지급된 임금총액을 그 기간의 총 일수로 나눈 금액이다.`,
					`3개월간의 임금총액은 다음 각 목과 같다.`,
					[
						`임금총액은 근로기준법상의 모든 임금이다.`,
						`평균임금 산정사유 발생일 이전 12개월 이내에 지급된 연차유급휴가근로수당과 정기 상여금의 3/12을 평균임금산정 기준임금총액에 산입한다.`,
					],
					`3개월간의 총일수는 다음 각 목과 같다.`,
					[
						`3개월간의 총일수는 평균임금산정 사유 발생일 이전 3개월간의 총 일수이다.`,
						`평균임금산정 사유 발생 당일은 포함되지 않는다.`,
						`근속기간 3개월 이내에 평균임금산정 사유 발생일이 있는 경우에는 그 기간의 총일수가 평균임금산정 대상기간이다.`,
					],
					`다음 각 목은 평균임금산정 대상기간에서 제외한다.`,
					[
						`근로계약을 체결하고 수습 중에 있는 사원이 수습을 시작한 날부터 3개월 이내의 기간`,
						`${company}의 귀책사유로 휴업한 기간`,
						`출산전후휴가 기간`,
						`육아휴직 기간`,
						`업무상 부상 또는 질병으로 요양하기 위하여 휴업한 기간`,
						`${companyUn} 업무 외 부상이나 질병, 그 밖의 사유로 회사의 승인을 받아 휴업한 기간`,
						`병역법∙향토예비군설치법 또는 민방위기본법에 의한 의무이행을 위하여 휴직하거나 근로하지 못한 기간. 다만, 그 기간 중 임금을 지급받은 경우에는 그러하지 아니하다.`,
						`노동조합및노동관계조정법 제2조 제6호의 규정에 의한 쟁의행위기간`,
					],
					`본 조항에 의거 계산한 평균임금이 통상임금보다 낮은 경우 그 통상임금을 평균임금으로 한다.`,
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
			},
			{
				title: '일할 계산',
				content: [
					'사원이 결근으로 근로를 제공하지 못하면 결근일의 1일 소정근로시간에 해당하는 통상임금과 해당 결근일이 있는 주의 주휴수당(1일 소정근로시간의 시간급 통상임금)을 공제한다.',
					'사원이 지각으로 근로를 제공하지 못하면 해당 시간분의 시간급 통상임금을 공제한다.',
					'사원이 조퇴∙외출∙이석(離席)으로 근로를 제공하지 못하면 해당 시간분의 시간급 통상임금을 공제한다.',
					'사원이 임금계산기간의 중도 퇴사로 근로를 제공하지 못하면 통상임금과 소정수당 및 약정수당을 당 임금계산기간의 재직기간에 비례하여 다음 각 호와 같이 지급한다.',
					[
						'월 28일의 임금계산기간: (재직일수 / 28일) X (통상임금+소정수당+약정수당)',
						'월 29일의 임금계산기간: (재직일수 / 29일) X (통상임금+소정수당+약정수당)',
						'월 30일의 임금계산기간: (재직일수 / 30일) X (통상임금+소정수당+약정수당)',
						'월 31일의 임금계산기간: (재직일수 / 31일) X (통상임금+소정수당+약정수당)',
					],
					'기타 필요한 사항은 별도로 정할 수 있다.',
				],
			},
			{
				title: '임금지급',
				content: [
					'임금은 1개월 이내의 기간을 설정하여 정기적으로 통화로 직접 사원에게 제2항의 경우를 제외하고, 그 전액을 지급한다.',
					'제1항에도 불구하고 다음 각 호의 경우 임금의 일부를 공제하고 지급한다.',
					[
						'국가 또는 지방자치단체가 징수하는 세금 및 공과금',
						'국민연금공단, 건강보험공단, 근로복지공단에서 징수하는 사원 부담의 사회보험료',
						'사원이 서면으로 명시적으로 동의∙요청한 금품',
						'기타 법령에 특별한 규정이 있는 경우',
						'지각, 조퇴, 외출, 이석(離席), 결근, 출근정지, 휴직 등으로 근로하지 못한 근로시간만큼의 통상임금 및 관련 수당',
					],
					'임금계산기간 및 임금지급일은 사원별 근로계약서 및 임금계약서에 의한다.',
					'임금지급일이 휴일 또는 휴무일 인 경우 그 직전의 시중은행 업무일에 지급한다.',
					`${companyUn} 각 사업장별로 임금대장을 작성하고 임금과 가족수당 계산의 기초가 되는 사항, 임금액 등을 임금을 지급할 때마다 적어야 한다.`,
					`${companyUn} 임금을 지급하는 때에는 사원에게 임금의 구성항목ㆍ계산방법, 제2항에 따라 임금의 일부를 공제한 경우의 내역 등을 적은 임금명세서를 서면(「전자문서 및 전자거래 기본법」 제2조제1호에 따른 전자문서를 포함한다)으로 교부하여야 한다.`,
					'기타 필요한 사항은 별도로 정한다.',
				],
			},
			{
				title: '비상시지불',
				content: [
					'다음 각 호의 경우의 사원에게는 기왕의 근로에 대한 임금을 지급한다.',
					[
						'출산하거나 질병에 걸리거나 재해를 당한 경우',
						'혼인 또는 사망한 경우',
						'부득이한 사유로 1주 이상 귀향하게 되는 경우',
					],
					'제1항에 의하여 임금을 청구하고자 할 경우에는 7일 전에 회사에게 신청서를 제출하여야 한다.',
					'기타 필요한 사항은 별도로 정할 수 있다.',
				],
			},
			{
				title: '금품청산',
				content: [
					`${companyUn} 사원의 근로관계가 종료된 날로부터 14일 이내에 일체의 금품을 지급한다.`,
					'특별한 사정이 있을 경우에는 당사자간의 합의에 의하여 금품청산의 기일을 연장할 수 있다.',
					'기타 필요한 사항은 별도로 정할 수 있다.',
				],
			},
			{
				title: '법정퇴직금',
				content: [
					'사원이 1년 이상 계속 근로하고 퇴직하였을 때에는 1년에 대하여 평균임금 30일분의 퇴직금을 지급한다.',
					'퇴직금은 근로계약일로부터 기산하고, 1년을 초과하는 1년 미만의 단수는 비례 계산하여 가산한다.',
					'기타 필요한 사항은 별도로 정할 수 있다.',
				],
			},
			{
				title: '법정퇴직금의 퇴직금 중간정산',
				content: [
					'사원은 다음 각 호에 해당하는 사유가 발생할 경우 별도 서면에 의한 퇴직금 중간정산을 신청할 수 있다.',
					[
						'무주택자인 사원이 본인 명의로 주택을 구입하는 경우',
						'무주택자인 사원이 주거를 목적으로 「민법」 제303조에 따른 전세금 또는 「주택임대차보호법」 제3조의2에 따른 보증금을 부담하는 경우. 이 경우 사원이 하나의 사업에 근로하는 동안 1회로 한정한다.',
						'사원이 6개월 이상 요양을 필요로 하는 다음 각 목의 어느 하나에 해당하는 사람의 질병이나 부상에 대한 의료비를 해당 사원이, 본인 연간 임금총액의 1천분의 125를 초과하여 부담하는 경우',
						['사원 본인', '사원의 배우자', '사원 또는 그 배우자의 부양가족'],
						'퇴직금 중간정산을 신청하는 날부터 거꾸로 계산하여 5년 이내에 사원이 「채무자 회생 및 파산에 관한 법률」에 따라 파산선고를 받은 경우',
						'퇴직금 중간정산을 신청하는 날부터 거꾸로 계산하여 5년 이내에 사원이 「채무자 회생 및 파산에 관한 법률」에 따라 개인회생절차개시 결정을 받은 경우',
						`${companyGa} 기존의 정년을 연장하거나 보장하는 조건으로 단체협약 및 취업규칙 등을 통하여 일정 나이, 근속시점 또는 임금액을 기준으로 임금을 줄이는 제도를 시행하는 경우`,
						`${companyGa} 사원과의 합의에 따라 소정근로시간을 1일 1시간 또는 1주 5시간 이상 변경하여 그 변경된 소정근로시간에 따라 사원이 3개월 이상 계속 근로하기로 한 경우`,
						'근로기준법 일부개정법률의 시행에 따른 근로시간의 단축으로 사원의 퇴직금이 감소되는 경우',
						'「재난 및 안전관리 기본법」 제66조제1항 각 호의 재난이 발생한 지역의 주거시설이 유실·전파 또는 반파된 피해. 이 경우, 주거시설은 가입자, 배우자, 「소득세법」제50조제1항제3호에 따른 사원(배우자를 포함한다)와 생계를 같이하는 부양가족이 거주하는 시설로 한정한다.',
						'「재난 및 안전관리 기본법」제66조제1항 각 호의 재난으로 인해 가입자의 배우자, 「소득세법」제50조제1항제3호에 따른 가입자(배우자를 포함한다)와 생계를 같이하는 부양가족이 실종된 경우',
						'「재난 및 안전관리 기본법」제66조제1항 각 호의 재난으로 인해 가입자가 15일 이상의 입원 치료가 필요한 피해를 입은 경우',
					],
					'제1항 각 호의 사유에 따라 퇴직금을 미리 정산하여 지급한 경우 사원이 퇴직한 후 5년이 되는 날까지 관련 증명 서류를 보존하여야 한다.',
					'퇴직금을 중간 정산한 사원이 최종 퇴직할 시 퇴직금 산정의 계속 근로 연수는 정산일로부터 기산한다.',
					'기타 필요한 사항은 별도로 정할 수 있다.',
				],
			},
			{
				title: '퇴직연금',
				content: [
					`${companyUn} 근로자퇴직급여보장법의 규정에 따라 사원대표의 동의를 얻어 확정급여형퇴직연금제도나 확정기여형퇴직연금제도 또는 개인형퇴직연금제도를 설정할 수 있다.`,
					'퇴직연금제도의 설정에 필요한 사항은 별도로 정할 수 있다.',
				],
			},
		];

		// if (['대한민국', '한국', '일본']?.includes(company)) {
		// 	const additionalSection = {
		// 		title: `소정근로시간`,
		// 		content: [
		// 			`사원의 소정근로시간은 아래와 같다.`,
		// 			[
		// 				`1일 소정근로시간은 휴게시간을 제외하고 8시간을 원칙으로 하되, 단시간 사원의 경우 1일 8시간을 한도로 근로계약에 의거 소정근로시간을 달리 정할 수 있다.`,
		// 				`1주 소정근로시간은 40시간을 원칙으로 하되, 단시간 사원의 경우 1주 40시간 한도로 근로계약에 의거 소정근로시간을 달리 정할 수 있다.`,
		// 			],
		// 			`15세 이상 18세 미만인 사원의 소정근로시간은 아래와 같다.`,
		// 			[
		// 				`1일 소정근로시간은 휴게시간을 제외하고 7시간을 원칙으로 하되, 단시간 사원의 경우 1일 7시간을 한도로 근로계약에 의거 소정근로시간을 달리 정할 수 있다.`,
		// 				`1주 소정근로시간은 35시간을 원칙으로 하되, 단시간 사원의 경우 1주 35시간 한도로 근로계약에 의거 소정근로시간을 달리 정할 수 있다.`,
		// 			],
		// 			`1주 소정근로시간은 월요일의 시업시각부터 기산하고, 1월 소정근로시간은 매월 1일의 시업시각부터 기산한다.`,
		// 			`경영상 필요한 경우 근로계약서 또는 임금 계약서의 작성을 통하여 기존에 제1항 및 제2항의 규정의 적용받는 것과 달리 1일 또는 1주 소정근로시간을 약정할 수 있다.`,
		// 			`고용노동부장관의 승인을 받은 감시적, 단속적 근로에 종사하는 자(경비직, 시설관리직, 운전기사 등) 및 관리, 감독업무 또는 기밀 사무를 취급하는 자는 본 규칙에서 정한 근로시간, 휴게와 휴일에 관한 규정은 적용하지 아니한다.`,
		// 			`기타 필요한 사항은 별도로 정할 수 있다.`,
		// 		],
		// 	};
		// 	baseSections.splice(1, 0, additionalSection); // "목적" 다음에 "소정근로시간" 삽입
		// }

		const excludedCompanies = ['주식회사 허브601'];

		// 조건에 따라 섹션 제거
		if (excludedCompanies?.includes(company)) {
			// "title"이 여러 개일 때 제거할 섹션 목록
			const excludedTitles = ['영업비밀 관리위원회'];

			baseSections = baseSections?.filter(
				(section) => !excludedTitles?.includes(section.title)
			);
		}

		return baseSections;
	}, [company, companyUn, companyGa]);

	useEffect(() => {
		setSectionCount(sections.length);
	}, [sections, setSectionCount]);

	const [openSections, setOpenSections] = useState({});

	useEffect(() => {
		const newOpenSections = sections.reduce((acc, section) => {
			acc[section.title] = allOpen;
			return acc;
		}, {});
		setOpenSections(newOpenSections);
	}, [allOpen, sections]);

	const toggleSection = (section) => {
		setOpenSections((prevState) => ({
			...prevState,
			[section]: !prevState[section],
		}));
	};
	return (
		<>
			<div className='jang sectionup'>제3장 임금제도</div>
			{sections.map((section, index) => (
				<div key={index}>
					<RuleSection
						number={index + previousSectionCount1 + previousSectionCount2 + 1}
						title={section.title}
						content={section.content}
						isOpen={!!openSections[section.title]}
						toggleOpen={() => toggleSection(section.title)}
						amended={section.amended}
					/>
				</div>
			))}
		</>
	);
}
