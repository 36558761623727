import React, { useEffect, useState } from 'react';
import {
	getFirestore,
	collection,
	getDocs,
	query,
	where,
} from 'firebase/firestore';
import { Form, Formik } from 'formik';
import { Button, Tab } from 'semantic-ui-react';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import MySelectInput from '../../../app/common/form/MySelectInput';
import { month, copyYear, year } from '../../../app/api/dropdownOption';
import axios from 'axios';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import * as XLSX from 'xlsx';
import { clearWorkDatesPaydocu } from '../companyActions';
import { useDispatch } from 'react-redux';
import { getWeek } from '../../../app/common/util/util';

// const API_BASE_URL = 'http://localhost:5000';
const API_BASE_URL = process.env.REACT_APP_API_SERVER_URL; // 실제 서버 URL로 변경하세요.

export default function PaydocuCopy({ companyId }) {
	const history = useHistory();
	const dispatch = useDispatch();
	const [isCopying, setIsCopying] = useState(false);
	const [isMounted, setIsMounted] = useState(true);
	const [employees, setEmployees] = useState([]); // 직원별 데이터를 저장할 상태

	useEffect(() => {
		setIsMounted(true);
		return () => {
			setIsMounted(false);
		};
	}, []);

	useEffect(() => {
		dispatch(clearWorkDatesPaydocu()); // 각 탭 진입 시 초기화
	}, [dispatch]);

	// 엑셀 템플릿 다운로드 기능
	const generateExcelTemplateData = async (
		targetYear,
		targetMonth,
		workerType,
		companyId
	) => {
		const db = getFirestore();

		// 시급직 또는 일급직에 따라 Firestore 쿼리 조건 설정
		const workerQuery =
			workerType === '시급직'
				? query(
						collection(db, 'workers'),
						where('hostUid', '==', companyId),
						where('isTimeWorker', '==', true)
				  )
				: query(
						collection(db, 'workers'),
						where('hostUid', '==', companyId),
						where('isDayWorker', '==', true),
						where('isTimeWorker', '==', '')
				  );

		const querySnapshot = await getDocs(workerQuery);

		const header = ['이름', '이메일', '년도', '월'];
		const daysInMonth = new Date(targetYear, targetMonth, 0).getDate();

		// 헤더에 날짜와 요일 추가
		for (let day = 1; day <= daysInMonth; day++) {
			const date = new Date(targetYear, targetMonth - 1, day);
			const weekday = date.toLocaleDateString('ko-KR', { weekday: 'short' });
			header.push(`${day} (${weekday})`);
		}
		header.push('특별상여금');

		const templateData = [header];

		// Firestore에서 가져온 직원 데이터로 sampleRow 생성 (이메일이 존재하는 경우만 추가)
		querySnapshot.forEach((doc) => {
			const workerData = doc.data();
			if (
				workerData.worker.email &&
				workerData.worker.email.trim() !== '' &&
				!workerData.retiredDate
			) {
				// 이메일이 존재하는 경우만 처리 퇴사자가 아닌경우
				const sampleRow = [
					workerData.worker.name || '이름 없음',
					workerData.worker.email,
					targetYear.toString(),
					targetMonth.toString(),
					...Array(daysInMonth).fill(''),
					'', // 특별상여금
				];
				templateData.push(sampleRow);
			}
		});

		return templateData;
	};

	const downloadExcelTemplate = async (targetYear, targetMonth, workerType) => {
		try {
			const excelData = await generateExcelTemplateData(
				targetYear,
				targetMonth,
				workerType,
				companyId
			);
			const worksheet = XLSX.utils.aoa_to_sheet(excelData);
			const workbook = XLSX.utils.book_new();
			const sheetName =
				workerType === '시급직' ? '시급직 근태 데이터' : '일용직 근태 데이터';
			XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
			XLSX.writeFile(
				workbook,
				`${workerType}_급여_엑셀_템플릿_${targetYear}_${targetMonth}.xlsx`
			);
		} catch (error) {
			console.error('엑셀 템플릿 생성 중 오류 발생:', error);
			toast.error('엑셀 템플릿 다운로드 중 오류가 발생했습니다.');
		}
	};

	// 엑셀 업로드 후 데이터 처리
	const handleExcelUpload = async (e) => {
		const file = e.target.files[0];
		if (file) {
			const reader = new FileReader();
			reader.onload = async (event) => {
				const data = new Uint8Array(event.target.result);
				const workbook = XLSX.read(data, { type: 'array' });
				const sheetName = workbook.SheetNames[0];
				const worksheet = workbook.Sheets[sheetName];
				const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

				if (jsonData.length > 1) {
					// 비동기로 workerIdMap을 가져오기
					const workerIdMap = await getWorkerIdMap(companyId);
					const [headerRow, ...dataRows] = jsonData;
					const employeesData = [];

					dataRows.forEach((row) => {
						const email = row[1];
						const workerId = workerIdMap[email]; // 매핑에서 workerId 가져옴

						// workerId가 존재하지 않으면 해당 직원은 스킵
						if (!workerId) {
							console.warn(
								`Worker with email ${email} not found in workerIdMap.`
							);
							return;
						}

						const year = parseInt(row[2]);
						const month = parseInt(row[3]).toString().padStart(2, '0');
						const rowSpecialBonus = row[row.length - 1] || '';

						let newWorkDates = []; // 각 행별로 초기화

						let weekInputState = {}; // 각 주별로 근무 시간을 집계하기 위한 임시 상태

						headerRow.forEach((header, colIndex) => {
							if (colIndex >= 4 && colIndex < headerRow.length - 1) {
								const day = parseInt(header.split(' ')[0]);
								const value = String(row[colIndex] || 0);

								if (!isNaN(day) && value > 0) {
									const dateObj = new Date(year, month - 1, day);
									const dayIndex = dateObj.getDay(); // 요일 인덱스
									const weekIndex = Math.floor((day - 1) / 7); // 주 인덱스
									const weekNumber = getWeek(dateObj); // 주 번호

									// 주별 누적 근무 시간 계산
									if (!weekInputState[weekIndex])
										weekInputState[weekIndex] = {};
									weekInputState[weekIndex][dayIndex] =
										(weekInputState[weekIndex][dayIndex] || 0) + value;

									// Calculate sumOfWorkHours after updating the state
									const sumOfWorkHours = Object.values(
										weekInputState[weekIndex]
									).reduce((a, b) => +a + +b, 0);

									newWorkDates.push({
										weekNumber,
										sumOfWorkHours, // 주별 근무 시간 합계
										weekIndex,
										dayIndex,
										value,
										date: day.toString(),
										month: month.toString().padStart(2, '0'),
										year: year.toString(),
										// workerEmail: email,
										// workerName,
									});
								}
							}
						});
						employeesData.push({
							workerId,
							workerEmail: email,
							workDates: newWorkDates,
							specialBonus: rowSpecialBonus,
						});
					});

					setEmployees(employeesData);
					toast.success('엑셀 데이터를 성공적으로 변환했습니다!');
				} else {
					toast.error('엑셀 파일에 유효한 데이터가 없습니다.');
				}
			};
			reader.readAsArrayBuffer(file);
		}
	};

	// 비동기적으로 workerIdMap 가져오기
	const getWorkerIdMap = async (companyId) => {
		const db = getFirestore();
		const workerIdMap = {};

		const workerQuery = query(
			collection(db, 'workers'),
			where('hostUid', '==', companyId)
		);

		const querySnapshot = await getDocs(workerQuery);
		querySnapshot.forEach((doc) => {
			const workerData = doc.data();
			if (workerData.worker.email) {
				workerIdMap[workerData.worker.email] = doc.id;
			}
		});

		return workerIdMap;
	};

	const copyPayrollData = (
		sourceYear,
		sourceMonth,
		targetYear,
		targetMonth,
		workerType
	) => {
		const sourceYearMonth = sourceYear + sourceMonth;
		const targetYearMonth = targetYear + targetMonth;

		const confirmCopy = window.confirm(
			`${sourceYear}년 ${sourceMonth}월을 ${targetYear}년 ${targetMonth}월로 ${workerType} 급여 데이터를 복사하시겠습니까?`
		);
		if (!confirmCopy) return;

		setIsCopying(true);

		const postData = {
			sourceMonth: sourceYearMonth,
			targetMonth: targetYearMonth,
			companyId: companyId,
			employees,
		};

		const routeMap = {
			월급직: 'copyPayrollData',
			시급직: 'hourlyWorkerWageCopyRoutes',
			일용직: 'daylyWorkerWageCopyRoutes',
			사업소득직: 'bizWorkerWageCopyRoutes',
		};

		axios
			.post(`${API_BASE_URL}/${routeMap[workerType]}`, postData)
			.then((result) => {
				if (isMounted) {
					toast.success(result.data.message);
					history.push('/paydocus');
				}
			})
			.catch((error) => {
				if (isMounted) {
					if (error.response) {
						console.error(
							`Error: ${error.response.status} - ${error.response.statusText}\n`,
							`URL: ${error.config.url}\n`,
							`Response: ${error.response.data}`
						);
						toast.error(
							`에러 발생: ${error.response.status} - ${
								error.response.statusText
							}\n${
								error.response.data.error || '자세한 오류는 콘솔을 확인하세요.'
							}`
						);
					} else if (error.request) {
						console.error(
							'Error: No response received from server.',
							error.request
						);
						toast.error(
							'서버로부터 응답이 없습니다. 네트워크 연결을 확인해주세요.'
						);
					} else {
						console.error('Error:', error.message);
						toast.error(`요청 중 오류 발생: ${error.message}`);
					}
				}
			})
			.finally(() => {
				if (isMounted) {
					setIsCopying(false);
				}
			});
	};

	const renderForm = (workerType) => (
		<Formik
			initialValues={{
				sourceYear: '',
				sourceMonth: '',
				targetYear: '',
				targetMonth: '',
			}}
			validationSchema={Yup.object({
				sourceYear: Yup.string().required('복사할 원본 연도를 선택해주세요.'),
				sourceMonth: Yup.string().required('복사할 원본 월을 선택해주세요.'),
				targetYear: Yup.string().required('복사될 대상 연도를 선택해주세요.'),
				targetMonth: Yup.string().required('복사될 대상 월을 선택해주세요.'),
			})}
			onSubmit={(values, { setSubmitting }) => {
				try {
					copyPayrollData(
						values.sourceYear,
						values.sourceMonth,
						values.targetYear,
						values.targetMonth,
						workerType
					);
					setSubmitting(false);
				} catch (error) {
					console.error(error.message);
					toast.error(error.message);
					setSubmitting(false);
				}
			}}>
			{({ isSubmitting, isValid, values }) => (
				<>
					<Form
						className='ui form'
						style={{ display: 'flex', justifyContent: 'space-between' }}>
						<div style={{ width: '19.5%', marginRight: '3px' }}>
							<MySelectInput
								label='복사할 원본 연도'
								name='sourceYear'
								options={year}
							/>
						</div>
						<div style={{ width: '19.5%', marginRight: '3px' }}>
							<MySelectInput
								label='복사할 원본 월'
								name='sourceMonth'
								options={month}
							/>
						</div>
						<div style={{ width: '19.5%', marginRight: '3px' }}>
							<MySelectInput
								label='복사될 대상 연도'
								name='targetYear'
								options={copyYear}
							/>
						</div>
						<div style={{ width: '19.5%', marginRight: '3px' }}>
							<MySelectInput
								label='복사될 대상 월'
								name='targetMonth'
								options={month}
							/>
						</div>
						<Button
							loading={isCopying || isSubmitting}
							disabled={isCopying || isSubmitting || !isValid}
							floated='left'
							type='submit'
							size='large'
							positive
							content={`${workerType} 월 전체 급여 복사`}
						/>
					</Form>

					{/* 시급직 전용 엑셀 템플릿 및 업로드 */}
					{workerType === '시급직' && (
						<>
							<div style={{ marginTop: '20px' }}>
								<Button
									primary
									onClick={() => {
										const targetYear =
											document.querySelector('[name="targetYear"]').value || '';
										const targetMonth =
											document.querySelector('[name="targetMonth"]').value ||
											'';
										downloadExcelTemplate(targetYear, targetMonth, workerType);
									}}
									disabled={!values.targetYear || !values.targetMonth}>
									{workerType} 엑셀 템플릿 다운로드
								</Button>

								<input
									type='file'
									accept='.xlsx, .xls'
									onChange={handleExcelUpload}
									style={{ marginTop: '10px' }}
									disabled={!values.targetYear || !values.targetMonth}
								/>
							</div>
							<h3>
								* 복사대상 년도와 월을 선택하시면 해당 년월의 일자와 요일이
								표시된 엑셀파일을 다운로드 받으실 수 있습니다.
							</h3>

							<strong style={{ color: 'purple' }}>
								* 복사하는 월에 재직하지 않은 직원과 이메일이 등록되지 않은
								직원은 엑셀업로드가 되지 않습니다.
							</strong>
						</>
					)}
				</>
			)}
		</Formik>
	);

	const panes = [
		{
			menuItem: '월급직 복사',
			render: () => <Tab.Pane>{renderForm('월급직')}</Tab.Pane>,
		},
		{
			menuItem: '시급직 복사',
			render: () => <Tab.Pane>{renderForm('시급직')}</Tab.Pane>,
		},
		{
			menuItem: '일용직 복사',
			render: () => <Tab.Pane>{renderForm('일용직')}</Tab.Pane>,
		},
		{
			menuItem: '사업소득직 복사',
			render: () => <Tab.Pane>{renderForm('사업소득직')}</Tab.Pane>,
		},
	];

	return <Tab panes={panes} />;
}
